import React, { useState, useEffect, useCallback } from "react";

import IGnpWindow from "../GnpWindow/IGnpWindow";
import IActiveImage from "./IActiveImage";
import ILightboxLink from "./ILightboxLink";
import GnpWindow from "../GnpWindow/GnpWindow";

let w;
let d;
let b;
let img;

export const GnpReactLightbox = () => {
    let [activeLink, setActiveLink] = useState<ILightboxLink | null | undefined | any>();
    let [activeImage, setActiveImage] = useState<IActiveImage>({ state: 'unloaded' });

    let links: any[] = [];

    const scan = useCallback((index = 0) => {
        let elements = d.querySelectorAll(".lightbox_link");
        elements.forEach((element: any & HTMLElement) => {
            let group = element.dataset.group || '';
            let source = element.dataset.source;
                        
            let images = [
                ...(source as any).split(','),
                ...Array.from(elements).filter((e: HTMLElement & any) => e.dataset.group === group).map((e: any & HTMLElement) => e.dataset.source)
            ];

            let link = { element, images, group, index: index };

            if (!element.classList.contains('lightbox_link_activated')) {
                let icon = d.createElement("i");
                icon.setAttribute("class", "fa fa-plus");
                let veil = d.createElement("div");
                veil.setAttribute("class", "lightbox_veil");
                veil.append(icon);

                element.append(veil);
                element.classList.add('lightbox_link_activated');
            }

            element.onclick = e => activate(link);
            links.push(link);
        });
    }, []);

    const launch = () => {
        setActiveImage({
            orientation: img.naturalHeight > img.naturalWidth ? 'portrait' : 'landscape',
            state: 'complete'
        });
    };

    const isCatalog = () => (activeLink as any).images.length > 1;

    const getFrameStyle = () => {
        return activeImage.state !== 'complete'
            ? {
                width: 0,
                height: 0,
                opacity: 0
            } : activeImage.orientation === 'landscape'
                ? {
                    opacity: 1,
                    width: (w.screen.availHeight * (img.naturalWidth / img.naturalHeight)),
                    height: '100vh'
                } : {
                    opacity: 1,
                    width: (w.screen.availHeight * (img.naturalWidth / img.naturalHeight)),
                    height: '100vh'
                };
    };

    const activate = (link: ILightboxLink) => {
        setActiveLink(link);
        setActiveImage({ state: 'loading' });

        img.src = '';
        img.src = link.images[link.index];
    };

    const deactivate = e => {
        setActiveLink(null);
        setActiveImage({ state: 'unloaded', orientation: null } as any);
    };

    const onImgClick = e => {
        e.cancelBubble = true;
        e.preventDefault();
        e.stopPropagation();
        return;
    };

    const prev = e => {
        e.cancelBubble = true;
        e.preventDefault();
        e.stopPropagation();
        activeLink.index = activeLink.index === 0
            ? activeLink.images.length - 1
            : activeLink.index - 1;
        activate(activeLink);
        return;
    };

    const next = e => {
        e.cancelBubble = true;
        e.preventDefault();
        e.stopPropagation();
        activeLink.index = activeLink.index === activeLink.images.length - 1
            ? 0
            : activeLink.index + 1;
        activate(activeLink);
        return;
    };

    useEffect(() => {
        img = new Image();
        img.onload = e => launch();
        img.onerror = console.log;

        w = window as unknown as IGnpWindow;
        w.gandp = w.gandp || new GnpWindow();
        d = w.document;
        b = d.body;

        w.gandp.lightbox = { scan, links: () => links };

        scan();
    }, []);

    let isLightboxActive = activeImage?.state !== 'unloaded';
    let isComplete = activeImage?.state === 'complete';
    let isLoading = activeImage?.state === 'loading';

    return <div className="lightbox" style={{ display: isLightboxActive ? 'flex' : 'none' }} onClick={deactivate}>
        <div className="viewer">
            {
                isComplete &&
                <div className="close" onClick={deactivate}>
                    <i className="fa fa-close"></i>
                </div>
            }
            {
                isComplete && isCatalog() &&
                <div className="prev" onClick={prev}>
                    <i className="fa fa-chevron-left"></i>
                </div>
            }
            {
                isComplete && isCatalog() &&
                <div className="next" onClick={next}>
                    <i className="fa fa-chevron-right"></i>
                </div>
            }
            {
                isLoading &&
                <i className="fa fa-spinner"></i>
            }
            <div className="frame" style={getFrameStyle()}>
                {
                    isComplete &&
                    <img src={activeLink.images[activeLink.index]}
                        className={activeImage.orientation}
                        onClick={onImgClick}
                    />
                    || null
                }
            </div>
        </div>
    </div>;
};

export default GnpReactLightbox;