import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router";
import { urls } from "../../TypeScript/endpoints";

import GnpReactLightbox from "../widgets/GnpReactLightbox/Index";
import EmployeeDirectory from "./apps/employee_directory";

const Clients = lazy(() => import("./aboutus/Clients"));
const Article = lazy(() => import("./aboutus/Article"));
const About = lazy(() => import("./aboutus/index"));
const Home = lazy(() => import("./home/index"));
const Services = lazy(() => import("./services/index"));
const Inventory = lazy(() => import("./inventory/index"));
const Contact = lazy(() => import("./contactus/index"));
const Login = lazy(() => import("./login/index"));
const Jobs = lazy(() => import("./jobs/index"));
const Liquidations = lazy(() => import("./liquidations/index"));
const OurTeam = lazy(() => import("./aboutus/OurTeam"));
const Testimonials = lazy(() => import("./aboutus/Testimonials"));
const Account = lazy(() => import("./account/index"));
const Sustainability = lazy(() => import("./aboutus/Sustainability"));
const Files = lazy(() => import("./apps/files/index"));
const Orders = lazy(() => import("./apps/orders/index"));
const CRM = lazy(() => import("./apps/crm/home"));
const BOL = lazy(() => import("./apps/bol/index"));
//const CRM = lazy(() => import("./apps/crm/index"));
const Portfolio = lazy(() => import("./portfolio/index"));
const Receipts = lazy(() => import("./apps/receipts/index"));
const ReceiptUpload = lazy(() => import("./apps/receiptupload/index"));
const Quotes = lazy(() => import("./apps/quotes/index"));
const PIM = lazy(() => import("./apps/pim/index"));

const { prod: { cdn },  services: { frontdoor } } = urls;

import "../sass/global.scss";

export const App: React.FC = ({ children, state }) => {    
    let style = { 
        background: `url('${frontdoor}/cms/gnp/bgWhiteBricks.png')`,
        backgroundRepeat: 'repeat',
        backgroundAttachment: 'fixed',
        maxWidth: state.dom.appJarWidth === -1 ? '100vw' : state.dom.appJarWidth + 'px'
    };
    
    return <div className="min-h-screen text-center">
        <div className="text-gslate min-h-screen" style={style}>
            <React.Suspense fallback={<></>}>
                <GnpReactLightbox />
                <Routes>
                    <Route path="/services/:service?/:itemId?" element={<Services state={state} />} />
                    <Route path="/inventory" element={<Inventory state={state} />} />
                    <Route path="/account" element={<Account state={state} />} />
                    <Route path="/aboutus/ourteam" element={<OurTeam state={state} />} />
                    <Route path="/aboutus/clients" element={<Clients state={state} />} />
                    <Route path="/aboutus/testimonials" element={<Testimonials state={state} />} />
                    <Route path="/aboutus/sustainability" element={<Sustainability state={state} />} />
                    <Route path="/aboutus/:articleName" element={<Article state={state} />} />
                    <Route path="/aboutus/" element={<About state={state} />} />
                    <Route path="/portfolio/:action?/:id?" element={<Portfolio state={state} />} />
                    <Route path="/apps/files" element={<Files state={state} />} />
                    <Route path="/apps/orders" element={<Orders state={state} />} />
                    <Route path="/apps/pim" element={<PIM state={state} />} />
                    <Route path="/apps/crm" element={<CRM state={state} />} />
                    <Route path="/apps/bol" element={<BOL state={state} />} />
                    <Route path="/apps/receipts" element={<Receipts state={state} />} />
                    <Route path="/apps/receiptupload" element={<ReceiptUpload state={state} />} />
                    <Route path="/apps/employee_directory" element={<EmployeeDirectory state={state} />} />
                    <Route path="/contactus/:action?" element={<Contact state={state} />} />
                    <Route path="/jobs/:id?" element={<Jobs state={state} />} />
                    <Route path="/liquidations/:id?" element={<Liquidations state={state} />} />
                    <Route path="/login/:action?" element={<Login state={state} />} />
                    <Route path="/" element={<Home state={state} />} />
                </Routes>
            </React.Suspense>
        </div>
    </div>;
};

export default App;