import React from 'react';

import { renderToPipeableStream } from 'react-dom/server';
import { StaticRouter } from "react-router-dom/server";
import { BrowserRouter } from "react-router-dom";
import { getMetaTitle } from "./helpers/Meta";
import { google_apikey } from './creds';

import App from "./pages/App";

export const renderStream = (req, res) => {
    const entryPoint = ["/build/vendors.js", "/build/client.js"];

    const { pipe, abort: _abort } = renderToPipeableStream(
        <HTML state={res.locals.state} mode="server" />,
        {
            bootstrapScripts: entryPoint,
            onAllReady() {
                res.status(200);
                res.set("Content-type", "text/html");
                pipe(res);
            },
            onShellError() {
                res.status(500);
                res.send("<!doctype html><p>Loading...</p>");
            },
        }
    );
};

export const HTML = ({ state, mode }) => {
    let { metaTimestamp, page, siteRoot, url } = state.root;

    const content = <html>
        <head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" type="image/png" href="https://media.umbraco.io/michaels-considerate-platypus/4vujer5b/favicon.png" />
            <link rel="manifest" href="/manifest.json" />           
            <title>{getMetaTitle(page?.metaTitle || siteRoot?.metaTitle)}</title>
            <meta name="keywords" content="G&P Construction LLC" />
            <meta name="description" content={page?.metaDescription || siteRoot?.metaDescription} />
            
            <meta property="og:title" content={`${getMetaTitle(page?.metaTitle || siteRoot?.metaTitle)}`} />
            <meta property="og:description" content={page?.metaDescription || siteRoot?.metaDescription} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content={`${siteRoot.siteURL.substr(0, siteRoot.siteURL.length - 1) + siteRoot.siteURL.pathname + siteRoot.siteURL.search + siteRoot.siteURL.hash}`} />
            <meta property="og:image" content="https://frontdoor.gandpconstruction.com/sites/gnp-companies/gnp_thumbnail.png" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="G&P Construction LLC" />
            <meta property="og:publish_date" content={metaTimestamp} />
            <meta property="og:published_date" content={metaTimestamp} />
            <meta property="og:publish_time" content={metaTimestamp} />
            <meta property="og:published_time" content={metaTimestamp} />            
            <meta property="article:published_time" content={metaTimestamp} />
            <meta property="article:publish_time" content={metaTimestamp} />
            <meta property="article:published_date" content={metaTimestamp} />
            <meta property="article:publish_date" content={metaTimestamp} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:domain" content="gandpconstruction.com" />
            <meta name="twitter:url" content={`${siteRoot.siteURL.substr(0, siteRoot.siteURL.length - 1) + siteRoot.siteURL.pathname + siteRoot.siteURL.search + siteRoot.siteURL.hash}`} />
            <meta name="twitter:title" content={`${getMetaTitle(page?.metaTitle || siteRoot?.metaTitle)}`} />
            <meta name="twitter:description" content={page?.metaDescription || siteRoot?.metaDescription} />
            <meta name="twitter:image" content="https://frontdoor.gandpconstruction.com/sites/gnp-companies/gnp_thumbnail.png" />
            
            <link rel="stylesheet" href="/css/global.css" />
            <link rel="stylesheet" href="/css/antd.css" />
            <link rel="stylesheet" href="/css/tailwind.css" />
            <link rel="stylesheet" href="/css/splide.min.css" />

            <link rel="stylesheet" href="/fontawesome/css/fontawesome.css" async />
            <link rel="stylesheet" href="/fontawesome/css/brands.min.css" async />
            <link rel="stylesheet" href="/fontawesome/css/solid.min.css" async />
            
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            
            <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
            
            <script src="/js/google.analytics.head.js"></script>
            
        </head>
        <body>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MN9LZ3X" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
            <div id="app">
                {
                    mode === 'server'
                        ? <StaticRouter location={state.root.url}><App state={state} /></StaticRouter>
                        : <BrowserRouter><App state={state} /></BrowserRouter>
                }
            </div>
            <script dangerouslySetInnerHTML={{ __html: `
                window.gandp = { 
                    state: ${JSON.stringify(state)}
                };
            `}}>
            </script>
           
            <script dangerouslySetInnerHTML={{ __html: `
                <!--
                    //if('serviceWorker in navigator') { navigator.serviceWorker.register('sw.js'); }
                //-->            
            `}}>
            </script>
        </body>
    </html>;

    return content;
};

export default renderStream;