import React from "react";
import Header from "../../../components/Header";
import useDom from "../../../hooks/useDom";
import Layout from "../../../components/Layout";
import Footer from "../../../components/Footer";
import Page from "../../../components/Page";

export const EmployeeDirectory: React.FC = ({ state: loaderData }) => {
    let dom = useDom(loaderData.dom);
    
    return <>
        <Header root={loaderData.root} header={loaderData.header} dom={dom} />
        <Layout>
            <Page>
                <iframe src="https://view.monday.com/embed/2807150129-bcd3a1c4b7f613fd9b0c9a2c12bc4b29?r=use1" style={{ minHeight: "calc(100vh - 90px)", width: "100%", height: "100%", border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)" }}></iframe>
            </Page>            
        </Layout>
        <Footer root={loaderData.root} footer={loaderData.footer} user={loaderData.user} />
    </>
};

export default EmployeeDirectory;