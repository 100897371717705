import React, { useRef, useEffect } from "react";
import { message } from "antd";
import { urls } from "../../TypeScript/endpoints";

const { prod: { func } } = urls;

export const Footer = ({ footer, root, user }) => {
    let { footerHeight, logo, logoAdele, logoPromhe, logoBigliquidators, logoDCAuctions } = footer;
    let { siteRoot, siteMap } = root;
    let { webId, userId, username } = user;

    let refForm = useRef();
    let refEmail = useRef();
    let refNewsletter = useRef();
    let refSource = useRef();

    

    const onSubmitClick = async e => {
        if(refForm.current.checkValidity()) {
            let r: any = await fetch(`${func}/api/subscribe`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: refEmail.current.value,
                    newsletter: refNewsletter.current.value,
                    source: refSource.current.value
                })
            });
            r = await r.json();
    
            if(r.Success) {
                message.success('Email address subscribed.');
                refForm.current.reset();
            } else {
                message.error(r.Messages.join(', '));
            }
        } else {
            message.error('Please enter a valid email address.');
        }
    };

    return <footer className="flex justify-center text-left w-full bg-gslate text-white leading-5" style={{ minHeight: `${footerHeight}px`}}>
        <div className="bg-black/0 flex grow shrink-0 pt-[50px] flex-col flex-nowrap content-center justify-between w-full gap-3 sm:gap-[50px]">

            <div className="flex-col sm:flex-row footer_top grow shrink-0 flex px-[30px] justify-center mx-auto w-page max-w-full">
                <div className="flex grow sm:basis-1/3 flex-col py-10 pt-0 sm:py-0">
                    <div className="flex items-start">
                        <div className="md:mr-2">
                            <div><img src={logo?._url} className="lg:w-[90px]" /></div>
                        </div>
                        <div>
                            <div className="font-bold text-gcopper font-[sans-serif]">G&amp;P Construction</div>
                            <div className="font-[sans-serif]"><small>{siteRoot?.tagline}</small></div>                          
                        </div>
                    </div>
                </div>
                <div className="flex sm:basis-1/3 md:justify-center md:basis-1/3 grow py-10 pt-0 sm:py-0">
                    <div className="services_div">
                        <div className="font-bold text-gcopper"><a href="/Services">Services</a></div>
                        {
                            siteMap?.filter(s => s.route == "/Services")[0].children.map((s, i) => {
                                return <div key={i} className="link"><small><a href={s.route}>{s.metaTitle}</a></small></div>
                            })
                        }
                    </div>
                </div>
                <div className="flex sm:basis-1/3 md:basis-1/3 grow py-10 pt-0 sm:py-0">
                    <div className="w-full">
                        <div className="font-bold text-gcopper">News</div>
                        <div>
                            <small>Don't miss the next huge deal.<br />Sign up today for our newsletter.</small>
                        </div>
                        <div id="subscribe_email_form">
                            <form method="post" action="/api/subscribe" ref={refForm}>
                                <input type="hidden" name="subscribe_email_newsletter" ref={refNewsletter} value="gandpconstruction.com" />
                                <input type="hidden" name="subscribe_email_source" ref={refSource} value="gandpconstruction.com" />
                                <input required type="email" name="subscribe_email_address" ref={refEmail} placeholder="Email address" className="rounded-sm bg-[#333] text-white p-2 text-[.8em] w-full my-1" />
                                <div className="flex justify-end">
                                    <button type="button" onClick={onSubmitClick} className="bg-[#333] text-white px-3 py-1 rounded-sm shadow-sm mt-1"><small>Subscribe</small></button>
                                </div>
                            </form>
                        </div>
                        <div className="flex gap-5 text-gcopper">
                            <div><a href={siteRoot?.facebookUrl ?? ""} target="_blank"><i className="fa-brands fa-facebook-f"></i></a></div>
                            <div><a href={siteRoot?.linkedInUrl ?? ""} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></div>
                            <div><a href={siteRoot?.youTubeUrl ?? ""} target="_blank"><i className="fa-brands fa-youtube"></i></a></div>
                            <div><a href={siteRoot?.instagramUrl ?? ""} target="_blank"><i className="fa-brands fa-instagram"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center items-center sm:gap-3 md:gap-3">
                <div className="basis-1/2 lg:basis-auto px-3 sm:px-5 md:px-5">
                    <a target="_blank" href="https://adelecompany.com" title="Precision Storage Systems Installation">
                        <img src={logoAdele} className="w-[125px]" />
                    </a>
                </div>
                <div className="basis-1/2 lg:basis-auto px-3 sm:px-5">
                    <a target="_blank" href="https://promhe.com" title="Enterprise MHE Solutions">
                        <img src={logoPromhe} className="w-[125px]" />
                    </a>
                </div>
                <div className="basis-1/2 lg:basis-auto px-3 sm:px-5">
                    <a target="_blank" href="https://bigliquidators.com" title="High Quality Closeout Merchandise">
                        <img src={logoBigliquidators} className="w-[120px]" />
                    </a>
                </div>
                <div className="basis-1/2 lg:basis-auto px-3 sm:px-5">
                    <a target="_blank" href="https://dcauctions.com" title="The Best MHE Auctions">
                        <img src={logoDCAuctions} className="w-[95px]" />
                    </a>
                </div>
            </div>

            <div className="flex grow-0 shrink basis-1/4 sm:pt-[10px] justify-center py-5 px-3 sm:px-0 sm:py-3">
                <small className="flex flex-col sm:flex-row justify-center pb-5">
                    <div className="text-center py-5 sm:py-0">
                        <span>&copy; {new Date().getFullYear()} {siteRoot?.name}</span>
                    </div>
                    <div className="text-center sm:text-left">
                        <span className="hidden sm:inline"> &nbsp;|&nbsp; </span>
                        <a href="/privacy">Privacy Policy</a>
                        <span> &nbsp;|&nbsp; </span>
                        <a href="/terms">Terms &amp; Conditions</a>
                        <span className="hidden sm:inline"> &nbsp;|&nbsp; </span>
                    </div>
                    <div className="pt-3 sm:pt-0">
                        <a href="/contactus/feedback">Tell Us How We're Doing</a>                        
                        {
                            (webId || userId) && <>
                                <span> &nbsp;|&nbsp; </span>
                                <a href="/account">{webId ? "Account" : "Employees"}</a>
                            </>
                        }
                        <span> &nbsp;|&nbsp; </span>
                        {
                            (username === 'anonymous')
                                ? <a href="/login">Login</a>
                                : <a href="/logout">Logout</a>
                        }
                    </div>
                </small>
            </div>
        </div>
    </footer>
};

export default Footer;