import { useEffect } from "react";
import { State, useHookstate } from "@hookstate/core";
import { DOMState, TDOMState } from "../state/DOMState";

export const useDom = (domState?) => {
    let dom = useHookstate(domState || DOMState.New()) as State<TDOMState>;

    const collect = () => {
        dom.clientWidth.set(window.document.body.clientWidth);
        dom.clientHeight.set(window.document.body.clientHeight);
        dom.isMobile.set(window.document.body.clientWidth < 1025);
        dom.scrollState.set([window.scrollY, window.scrollX]);
        dom.url.set(window.location.href);
    };

    useEffect(() => {
        addEventListener('resize', collect);
        addEventListener('load', collect);
        addEventListener('scroll', collect);
        addEventListener('deviceorientation', collect);

        collect();

        return () => {
            removeEventListener('resize', collect);
            removeEventListener('load', collect);
            removeEventListener('scroll', collect);
            addEventListener('deviceorientation', collect);
        };
    }, []);

    return dom;
};

export default useDom;