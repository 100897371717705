import { IGnpCounter } from "../GnpCounter/IGnpCounter";

export class GnpWindow {
    constructor() {
        this.lightbox = {};
        this.env = "Development";
        this.sliders = {};
        this.carousels = {};
        this.callbacks = {};
        this.token = "";
        this.intervals = {};
        this.timeouts = {};
        this.counters = [];
        this.forms = {};
        this.scripts = {};
        this.stylesheets = {};
        this.modal;
        this.recaptcha;
        this.library = {};
    }

    library: Record<string, any>;
        
    lightbox: any;
    env: string;
    sliders: Record<string, any>;
    carousels: Record<string, any>;
    callbacks: Record<string, any>;  
    token: string;
    timeouts: Record<string, number>;
    intervals: Record<string, number>;
    counters: IGnpCounter[];
    forms: Record<string, HTMLFormElement>;
    scripts: Record<string, Promise<any>>;
    stylesheets: Record<string, Promise<any>>;
    modal;
    recaptcha;
}

export default GnpWindow;