import React, { useEffect } from "react";

import { urls } from "../../TypeScript/endpoints";
import { UserOutlined } from "@ant-design/icons";
import { State, useHookstate } from "@hookstate/core";
import { DOMState, TDOMState } from "../state/DOMState";
import { TRootState } from "../state/RootState.server";
import { THeaderState } from "../state/HeaderState.server";
import { useDom } from "../hooks/useDom";

const { services: { blob } } = urls;

type TProps = {
    header: THeaderState;
    root: TRootState;
    dom?: TDOMState;
    override: {
        linkParentCss?: string;
        linkCss?: string;
        headerCss?: string;
        headerStyle?: any;
    };
};

export const Header: React.FC<TProps> = ({ header: { headerType, headerHeight }, root, dom, user, override }) => {
    let state = dom || useDom(DOMState.New()) as State<TDOMState>;
    let type = useHookstate(headerType);

    const onMobileMenuButtonClick = e => { state.mobileMenuOpen.set(!state.mobileMenuOpen.get()); };
    const onMobileMenuClick = e => { state.mobileMenuOpen.set(!state.mobileMenuOpen.get()); };

    useEffect(() => { window.document.body.classList.toggle('noscroll', state.mobileMenuOpen.get()); }, [state.mobileMenuOpen.get()])

    const isTransparent = type.get() === 'transparent';
    const isDark = type.get() === 'dark';

    let className = `relative z-[100] block w-full text-center lg:text-left leading-normal`;

    if (isTransparent) { className += `bg-black/30 text-gconcrete dark:text-gcopper dark `; }
    else if (isDark) { className += `dark bg-gslate text-gconcrete `; }
    else { className += `text-gmidcopper `; }

    if (state.isMobile.get()) { className += "flex justify-center "; }
    else { }

    return <div>
        <header style={{ height: headerHeight, ...(override?.headerStyle || {}) }} className={`${className} ${override?.headerCss || ''}`}>
            <span className="absolute h-full lg:hidden items-center flex w-auto justify-end top-0 bottom-0 left-0 pl-4 z-[110]">
                <a href="tel:18554944832"><i className="text-gdeepcopper lg:hidden fa fa-phone text-[1.75rem] cursor-pointer p-2 rounded-md"></i></a>
            </span>
            <span className="absolute h-full lg:hidden items-center flex w-auto justify-end top-0 bottom-0 right-0 pr-4 z-[110]">
                <i onClick={onMobileMenuButtonClick} className="text-gdeepcopper lg:hidden fa fa-bars text-[1.75rem] cursor-pointer p-2 rounded-md"></i>
            </span>
            <div className="flex items-center justify-between h-full px-10 max-w-page_large mx-auto">
                <div className="flex no-wrap justify-between items-stretch h-full w-full">
                    <div className="h-full flex justify-center lg:justify-start items-center gap-10">
                        <div><a href="/"><img className="h-[65px] w-auto" src={`${blob}/graphics/logos/gandpconstruction-72x-72x.png`} alt="G&P Construction" /></a></div>
                        <div className="flex-col gap-1 text-left hidden xl:flex">
                            <div><a href={root.pitchDeck._url} target="_blank" className="leading-none italic"><small className="dark:text-gconcrete font-bold dark:font-normal">Click Here to View Our 2024 Flyer</small></a></div>
                            <div><a href="tel:18554944832" className="italic leading-none"><span className="font-bold dark:text-gcopper">+1 (855) 4-WHITEBOX</span></a></div>
                        </div>
                    </div>
                    <div className="hidden lg:flex h-full items-center gap-2 uppercase">
                        {
                            root.siteMap.map((page, i) => {
                                return <div key={i} className={`ddml h-[${headerHeight}] relative px-4`}>
                                    <div className={`h-desktop_header_height text-gdeepcopper dark:text-gdeepcopper ${override?.linkJarCss}`}>
                                        <a className="flex h-full items-center font-united" href={page.route}><h4>{page.name}</h4></a>
                                    </div>
                                    {
                                        page.children.length > 0
                                            ? <div className="text-left ddm absolute hidden mt-[-20px] top-[100%] left-0 z-[7100] items-stretch w-[300px] border-solid border-gconcrete border-[1px] shadow-lg shadow-black/10 bg-gslate text-gconcrete py-5 ml-[-10px]">
                                                {
                                                    page.children.map((c, i) => {
                                                        return <div key={i} className="flex items-center py-2 px-10">
                                                            <small><a href={c.route}>{c.metaTitle}</a></small>
                                                        </div>;
                                                    })
                                                }
                                            </div>
                                            : null
                                    }
                                </div>;
                            })
                        }
                        {/* <div className="h-full flex items-center my-auto align-middle">
                            <a href="/account" className="flex items-center h-full my-auto cursor-pointer">
                                <UserOutlined />
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
        </header>
        <div onClick={onMobileMenuClick} className={`fixed left-[-100%] top-0 bottom-0 flex z-[9999] w-full bg-none}`} style={{
            transition: 'left ease-in-out 200ms',
            left: state.mobileMenuOpen.get() ? '0' : '-100%'
        }}>
            <div className="shadow-lg border-r-gdeepcopper border-r-1 bg-gslate w-[84%] border-r-solid text-gdeepcopper drop-shadow-lg py-7 px-5 flex flex-col justify-between overflow-y-auto">
                <div className="h-auto text-left flex flex-col gap-3">
                    <div className="flex flex-col gap-4 justify-center">
                        {
                            root.siteMap?.map((page, i) => {
                                return <div key={i} className="text-gcopper">
                                    <div>
                                        <a href={`${page.route}`}><h3 className="font-united">{page.metaTitle}</h3></a>
                                    </div>
                                    {
                                        page.children.length > 0
                                        && <div className="text-gconcrete py-3 flex flex-col gap-2">
                                            {
                                                page.children.map((c, i) => {
                                                    return <div key={i} className="pl-3"><a href={c.route}>{c.title || c.metaTitle}</a></div>;
                                                })
                                            }
                                        </div>
                                    }
                                </div>;
                            })
                        }
                    </div>
                    <div className="text-gconcrete pl-3"><a className="text-gconcrete" href="/contactus">Message Us</a></div>
                    <div className="text-gconcrete pl-3"><a className="text-gconcrete" href="">{root.siteRoot?.phone?.display}</a></div>
                </div>
                <div className="flex items-center gap-5 h-[50px] justify-self-end mt-10">
                    <div className="flex h-full text-facebook justify-center items-center"><a className="text-facebook" href="" target="_blank"><i className="text-facebook flex fa-brands fa-facebook-f text-[1.75rem]"></i></a></div>
                    <div className="flex h-full text-linkedin justify-center items-center"><a className="text-linkedin" href="" target="_blank"><i className="flex fa-brands fa-linkedin-in text-[1.75rem]"></i></a></div>
                    <div className="flex h-full text-youtube justify-center items-center"><a className="youtube" href="" target="_blank"><i className="flex fa-brands fa-youtube text-[1.75rem]"></i></a></div>
                    <div className="flex h-full text-instagram justify-center items-center"><a className="instagram" href="" target="_blank"><i className="flex fa-brands fa-instagram text-[1.75rem]"></i></a></div>
                </div>
            </div>
        </div>
    </div>
};

export default Header;