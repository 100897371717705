import * as mobileDetect from "mobile-detect";

type TImageCache = Record<string, { status: 'success' | 'loading' | 'error' | 'noimage', orientation: 'landscape' | 'portrait' }>;

export const DOMState = {
    Init: (req) => {
        let md = new mobileDetect(req.get('user-agent'));

        return {
            isMobile: !!md.mobile(),
            device: {
                mobile: md.mobile(),
                phone: md.phone(),
                tablet: md.tablet(),
                userAgent: md.userAgent(),
                os: md.os(),
                iPhone: md.is('iPhone'),
                bot: md.is('bot'),
                version: md.version('Webkit'),
                versionStr: md.versionStr('Build'),
                match: md.match('playstation|xbox')
            }
        };
    },
    New: () => {
        return {
            url: "",
            mobileDetect: null as any,
            loadedScripts: [],
            placedScripts: [],
            isMobile: false,
            isMounted: false,
            scrollState: [0,0],
            mobileMenuOpen: false,
            linkedInFollowButton: null,
            clientWidth: 1600,
            clientHeight: 1024,
            scriptText: [] as string[],
            serverMessage: {} as Record<string, unknown>,
            appJarWidth: -1,
            backgroundImage: ""
        };
    }
};

let domState = DOMState.New();

export type TDOMState = typeof domState;