export const serviceUrls = {
    // Microsoft
    microsoft_graph: `https://graph.microsoft.com/v1.0`,
    azure_ad_identity: "https://login.microsoftonline.com",
    microsoft_identity_roles: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    
    // G&P Azure
    cosmos_nosql: "https://gnp-cosmosdb-nosql.documents.azure.com",    
    blob: "https://gnpstorage9.blob.core.windows.net",    
    frontdoor: "https://frontdoor.gandpconstruction.com",
    
    // Umbraco
    umbraco_api: `https://api.umbraco.io`,
    umbraco_cdn: `https://cdn.umbraco.io`,
    umbraco_media: `https://michaels-considerate-platypus.euwest01.umbraco.io/media`,
        
    // Blogger
    blogger: "https://www.googleapis.com/blogger/v3/blogs",
    
    // Monday
    monday: "https://api.monday.com/v2",

    // Sypht
    sypht: "https://auth.sypht.com/oauth2/token"
};

export type TServiceUrls = typeof serviceUrls;
export type TApps = { rest: string; rest_secure?: string; func: string; cdn: string; func_ngrok_mickler?: string };

export const dev: TApps = {
    rest: "https://gnp-rest-dev.azurewebsites.net",
    func: "https://fn.dev.gandpconstruction.com",
    cdn: "https://cdn.dev.gandpconstruction.com"
};

export const prod: TApps = {
    rest: "https://api.gandpconstruction.com",
    func: "https://fn.gandpconstruction.com",
    cdn: "https://cdn.gandpconstruction.com"
};

export const local: TApps = {
    rest: "http://localhost:5040",
    rest_secure: "https://localhost:5401",
    func: "http://localhost:7071",
    func_ngrok_mickler: "https://cunning-sole-peaceful.ngrok-free.app",
    cdn: "http://localhost:7071"
};

export const urls = {
    dev,
    prod,
    local,
    services: serviceUrls
};

export type TUrls = typeof urls;

export default (global): TApps => {
    let platform = global?.location?.origin ? "browser" : "node";
    let process = platform == "node" ? global : null;
    let window = platform == "browser" ? global : null;

    if(window) {
        let key = window?.location?.origin;
        return key?.indexOf("-dev") !== -1
            ? urls["dev"]
            : key?.indexOf("localhost") !== -1
                ? urls["local"]
                : urls["prod"];
    } else {
        let key = process.env.WEBSITE_HOSTNAME;
        return key?.indexOf("-dev") !== -1
            ? urls["dev"]
            : key?.indexOf("localhost") !== -1
                ? urls["local"]
                : urls["prod"];        
    }
};